module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"OGP Validator","short_name":"OGP Validator","start_url":"/","background_color":"#E3ECF5","theme_color":"#E3ECF5","display":"minimal-ui","icon":"src/assets/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"b981ce931b5582803b859726c48a3e10"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"api":"https://fonts.googleapis.com/css2","families":["Roboto:wght@400;700"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W8SQH24","includeInDevelopment":true,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
